<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: 'MORE+'
  },
  link: {
    type: String,
    default: '#'
  },
  target: {
    type: String,
    default: '_blank'
  }
})
</script>

<template>
  <nuxt-link :to="props.link" class="link" :target="target">{{ props.title }}</nuxt-link>
</template>

<style lang="scss" scoped>
.link {
  display: inline-block;
  border: 1px solid #000;
  padding: 10px 23px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;

  @include max-media(480) {
    font-size: 10px;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}
</style>
